import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/affilate_mono_repo_vilkencykel/gatsby/src/components/mdxLayout.js";
import { graphql, Link } from "gatsby";
import ProductsDisplay from "../../components/productDisplay.js";
import CategoriesLinks from "../../components/categoriesLinks.js";
export const query = graphql`
  query {
    allMongodbStoreProducts(sort: {fields: popularity_score, order: DESC}, filter: {brand: { eq: "rawbike" }}) {
      edges {
        node {
          	brand
			full_name
			id
			local_image_name
			model
			original_prices
			popularity_score
			prices
			product_id
			similair_products
			show_on_pages
			sub_product_type
			store
			url
			engine
			last_updated_date
			derived_properties {
				motor_type
				average_range_km
				number_of_gears
				battery_capacity
				frame_type
			}
			fields {
				brand_url
				full_name
				full_name_url
				model_url
			}
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "elcyklar-från-rawbike"
    }}>{`Elcyklar från RAWBIKE`}</h1>
    <p>{`Upplev svensk kvalitet och innovation med RAWBIKE elcykel, den perfekta lösningen för stadsmiljö. RAWBIKE erbjuder en spännande produktserie med modeller såsom RAWBIKE CITY, den flexibla RAWBIKE Hopfällbar Elcykel, och den kraftfulla RAWBIKE URBAN. Dessa svensk elcyklar är noggrant designade för att förenkla och försköna dina dagliga pendlingar, oavsett om du navigerar i stadstrafiken eller njuter av en fritidstur. Med en snygg balans mellan modern design och avancerad funktionalitet, erbjuder vi hopfällbara alternativ och smarta anpassningsbara lösningar med pedalassistans. Varje RAWBIKE är skapad för att ge dig en komfortabel, pålitlig och stilig körupplevelse. Låt RAWBIKE bli mer än bara ett transportmedel – gör det till en del av din livsstil.`}</p>
    <h2>Välkommen till RAWBIKE – Svensk Kvalitet och Innovation</h2>
    <p>RAWBIKE är din ultimata destination för elcyklar som förkroppsligar svensk kvalitet och innovation. Som ett ledande varumärke inom svensk elcykel-teknologi, står RAWBIKE för en harmonisk kombination av modern design och banbrytande funktionalitet. RAWBIKE elcyklar är utformade för att passa moderna stadsbors livsstil, vare sig det handlar om pendling eller fritidsbruk. Varje modell är noggrant designad för att erbjuda stil och praktiska fördelar, vilket gör dina vardagliga resor både bekväma och eleganta. Med högkvalitativa material och toppmodern teknik, strävar RAWBIKE efter att ge en förstklassig körupplevelse som matchar dina individuella behov. Upptäck skillnaden hos RAWBIKE – där säkerhet, hållbarhet och stil går hand i hand för en imponerande och pålitlig svensk elcykel. Valet av RAWBIKE är inte bara ett transportmedel, det är en livsstil.</p>
    <h2>RAWBIKE CITY – Din Bästa Partner i Stadstrafiken</h2>
    <p>Upplev dynamiken i stadens puls med RAWBIKE CITY – en elcykel för stadsmiljö som är perfekt designad för moderna stadsbor. Med sin stilrena och eleganta matte black finish blir RAWBIKE CITY inte bara en transportlösning, utan en modeaccessoar som matchar ditt urbana liv. Utrustad med en 250W motor, är den optimerad för att navigera smidigt genom stadstrafiken med en toppfart på 25 km/h.</p>
    <p>RAWBIKE CITY:s innovativa AVS-system från Atran Velo gör det enkelt att anpassa cykeln med olika tillbehör – ett perfekt val för dig som söker både stil och funktionalitet i ditt dagliga pendlande. Med en imponerande räckvidd på upp till 50 km ger den dig friheten att enkelt utforska staden på egna villkor. Välj RAWBIKE CITY för en elcykel som förenar estetisk design med praktisk anpassningsbarhet.</p>
    <h2>RAWBIKE Hopfällbar Elcykel 250E – Flexibilitet och Precision</h2>
    <p>Utnyttja modern teknologi med RAWBIKE Hopfällbar Elcykel 250E som erbjuder optimal flexibilitet för en aktiv och urban livsstil. Denna svenska elcykel är designad för att imponera med sin robusta 250W motor och ett pålitligt 48V/16Ah batteri, vilket ger en räckvidd på upp till 50 km – perfekt för dina dagliga pendlingsbehov. Med sin innovativa hopfällbara design kan du enkelt transportera och förvara din cykel, oavsett om det är i kollektivtrafiken eller i bilen. Den inbyggda GPS-tekniken säkerställer stöldskydd och ger en extra trygghet i stadsmiljön. Utformad för att klara nordiska förhållanden, är RAWBIKE Hopfällbar Elcykel utrustad med punkteringsskyddade däck, vilket gör den till en pålitlig följeslagare året runt. Välj RAWBIKE Hopfällbar Elcykel 250E för en harmonisk kombination av flexibilitet och precision, anpassad för det svenska klimatet.</p>
    <h2>RAWBIKE URBAN – Optimerad för Stadslivet</h2>
    <p>Upplev det moderna stadslivet med RAWBIKE URBAN, den ultimata elcykeln för stadsmiljö. Med en kraftfull 250W motor och effektiv pedalassistans säkerställer denna svenska innovation en smidig och energibesparande cykeltur genom stadens puls. RAWBIKE URBAN är utformad för att erbjuda enastående komfort med sin knus-sadel och robusta 26-tums aluminiumfälgar, vilket gör den till ett perfekt val för både långa och korta resor. Denna elcykel är inte bara funktionell, utan kommer i flera stilrena färger som matte black, ivory white och sand beige, för att passa din personliga stil. Med integrerade ljus och en intuitiv LCD-skärm håller RAWBIKE URBAN dig uppdaterad och säker oavsett var du befinner dig. Låt RAWBIKE URBAN bli en del av ditt vardagliga äventyr och upplev stadslivet på ett helt nytt sätt.</p>
    <h2>Köpguiden – Hitta Rätt RAWBIKE för Dig</h2>
    <p>Att välja rätt RAWBIKE elcykel för din livsstil och behov handlar om att förstå de unika styrkorna hos varje produktserie. För den moderna stadsbon som dagligen navigerar genom pulserande stadsmiljöer erbjuder RAWBIKE CITY den perfekta balansen mellan stil och funktion. Utrusta med en 250W motor och elegant design, är denna elcykel för stadsmiljö aptitligt anpassad för smidig pendling och modernt uttryck.</p>
    <p>För dem som eftersträvar flexibel mobilitet kombinerad med banbrytande teknik, är RAWBIKE Hopfällbar Elcykel 250E det ultimata valet. Den hopfällbara konstruktionen gör den idealisk för dem som pendlar både på cykel och kollektivtrafik, medan GPS-säkerheten skyddar din investering.</p>
    <p>Om du söker en elcykel med hög prestanda och komfort som passar både till snabba uppdrag och fritidsturer, är RAWBIKE URBAN det rätta valet. Med sin pedalassistans och bekväma funktioner är den optimerad för alla aspekter av stadslivet. Välj RAWBIKE som din pålitliga följeslagare och upplev framtidens hållbara pendlingslösning redan idag.</p>
    <ProductsDisplay data={props.data.allMongodbStoreProducts} location={props.location} mdxType="ProductsDisplay"></ProductsDisplay>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      